import browserInfo from 'browser-info'
import utils from '@/utils'

document.body.style.backgroundColor = '#000'

const funcs = {
  settingsSet(name, value) {
    localStorage && localStorage.setItem(name, value)
  },
  settingsGet(name) {
    return localStorage && localStorage.getItem(name)
  },
  videoLoadChannel(/* url */) {},
  videoPlay() {
    document.body.style.backgroundImage = 'url(img/Optimisty.jpg)'
    utils.dispatchEvent('videostart')
  },
  videoPause() {},
  videoStop() {
    document.body.style.backgroundImage = ''
    document.body.style.backgroundColor = '#000'
  },
  deviceInfo() {
    const browser = browserInfo()
    return {
      model: browser.name,
      firmware: browser.fullVersion + '-' + browser.os,
      width: window.innerWidth,
      height: window.innerHeight,
    }
  },
  getVolume() { return 50 },
  setVolume(/* volume */) {},
  runYouTubeApp(/* model */) {},
  runWinkApp() {},
  showSystemSettings(/* page */) {},
  exitApp() { location.reload() },
}

export default (args) => {
  args = args || {}
  args.params = args.params || []
  return new Promise((resolve, reject) => {
    if (!funcs[args.fName]) {
      return reject(`Unknown function "${args.fName}" for browser device`)
    }
    resolve(funcs[args.fName](...args.params))
  })
}
